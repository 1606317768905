import React, { useRef, useState, useEffect } from "react";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";
import scplbg from "../components/img/scplbg.png";
import html2canvas from "html2canvas";
import scpllogo from "../components/img/scpllogo.png";
import "../components/css/style2.css";
import "react-image-crop/dist/ReactCrop.css";

const ImageCrp = () => {
  const [crop, setCrop] = useState({ aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState();
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [imgSrc, setImgSrc] = useState("");
  const [aspect, setAspect] = useState(1);
  const [canvasDataUrl, setCanvasDataUrl] = useState(null);
  const combinedCanvasRef = useRef(null);
  const [fullName, setFullName] = useState("");

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
        const canvas = previewCanvasRef.current;
        setCanvasDataUrl(canvas.toDataURL());
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  useEffect(() => {
    if (canvasDataUrl && combinedCanvasRef.current) {
      const combinedCanvas = combinedCanvasRef.current;
      const context = combinedCanvas.getContext("2d");

      // Draw the background image
      const background = new Image();
      background.src = scplbg;
      background.onload = () => {
        combinedCanvas.width = background.width;
        combinedCanvas.height = background.height;
        context.drawImage(background, 0, 0);

        // Draw the uploaded image
        const uploadedImage = new Image();
        uploadedImage.src = canvasDataUrl;
        uploadedImage.onload = () => {
          const centerX = 3240 + 1500; // Adjust 1480 and 425 as needed
          const centerY = 435 + 950; // Adjust 550 and 425 as needed
          const radius = 1050; // Adjust as needed

          context.save(); // Save the current context state
          context.beginPath();
          context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
          context.clip();
          context.font = "20px Arial";
          context.fillStyle = "black";

          // context.fillText(fullName, 50, 50);
          context.drawImage(
            uploadedImage,
            3500, // Adjust as needed
            345, // Adjust as needed
            2300,
            2300
          );
          const text = fullName;
          const textX = 100 + 100;
          const textY = 100 + 100; // Adjust the distance from the bottom of the image

          context.fillStyle = "white";
          context.textAlign = "center";
          context.fillText(text, textX, textY);
        };
      };
    }
  }, [canvasDataUrl, fullName]);

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  const Gen = () => {
    const na = document.getElementById("name");
    const im = document.getElementById("image");
    im.style.display = "block";
    na.style.display = "none";
  };

  const download = () => {
    html2canvas(document.getElementById("img")).then(function (canvas) {
      const image = canvas.toDataURL("image/png", 1.0);
      const link = document.createElement("a");

      link.download = "SCPL STUDENT";
      link.href = image;
      link.click();
    });
  };

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result?.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  //   const downloadCombinedImage = () => {
  //     if (combinedCanvasRef.current) {
  //       const link = document.createElement("a");
  //       link.href = combinedCanvasRef.current.toDataURL();
  //       link.download = "combined_image.png";
  //       link.click();
  //     }
  //   };

  return (
    <div>
      <div className="container-fluid">
        <nav className="navbar navbar-default row">
          <div className="nav-header col-12">
            {/* <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12"> */}
            <a className="navbar-brand" href="#">
              <img
                alt="brand"
                id="brand2"
                className="img img-responsive"
                src={scpllogo}
              />
            </a>
            {/* </div> */}
            {/* <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <a className="navbar-brand" href="#">
                  <img
                    alt="Brand"
                    id="brand1"
                    className="img img-responsive1"
                    style={{ width: 148, float: "right", marginTop: "-5px" }}
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRjwLG26sf6Qyhg0x8JVkMa_YryqVgpZjMouBlpeU0zog&s"
                  />
                </a>
              </div> */}
            {/* </div> */}
          </div>
        </nav>
      </div>
      {/* <div style={{ padding: "25px" }}> */}
      <div>
        {/* <div className="row"> */}
        <div id="name">
          <div
            className="col-12"
            style={{
              display: "flex",
              border: " 2px solid black",

              width: "80%",
              // width: "89%",
              // marginLeft: "34%",
              // height: "296px",
              justifyContent: "center",
              border: "2px solid black",
              marginLeft: "10%",
              marginTop: "4%",
              backgroundColor: "white",
            }}
          >
            <div>
              <div>
                <h3 style={{ color: "dark blue" }}>
                  Generate I Am SCPL Attendee
                </h3>
              </div>
              <div>
                <form action="/ImageCropper">
                  <label htmlFor="fname" style={{ marginLeft: "-10px" }}>
                    {" "}
                    Enter Your Full name:{" "}
                  </label>
                  <input
                    type="text"
                    id="fname"
                    name="fname"
                    onChange={(e) => setFullName(e.target.value)}
                    style={{ margin: "8px", width: "260px" }}
                  />
                  <br />
                  <label htmlFor="" style={{ marginLeft: "-10px" }}>
                    Upload Your Photo:
                  </label>{" "}
                  <input
                    type="file"
                    accept="image/*"
                    onChange={onSelectFile}
                    style={{ margin: "8px", width: "260px" }}
                  />
                </form>
              </div>
              <div>
                {!!imgSrc && (
                  <ReactCrop
                    crop={crop}
                    onChange={(_, percentCrop) => setCrop(percentCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={aspect}
                    circularCrop
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={imgSrc}
                      style={{
                        width: "500px",

                        transform: `scale(${scale}) rotate(${rotate}deg)`,
                      }}
                      onLoad={onImageLoad}
                    />
                  </ReactCrop>
                )}
              </div>
            </div>
          </div>

          <div>
            <button
              className="btn btn-primary"
              onClick={Gen}
              style={{ margin: "15px" }}
            >
              Generate Image
            </button>
          </div>
        </div>

        <div
          id="image"
          className=" container-fluid  main"
          style={{
            display: "none",
          }}
        >
          <div className="row">
            {/* </div> <div className="col-12" style={{ justifyContent: "center" }}> */}
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div
                className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12"
                id="img"
                style={{
                  backgroundImage: `url(${scplbg})`,
                  height: "350px",
                  width: "350px",
                  backgroundSize: "cover",
                  marginTop: "-11px",
                  margin: "auto",
                  backgroundPosition: "center",
                  objectFit: "cover",

                  // marginLeft: "31%",
                }}
              >
                {!!completedCrop && (
                  <>
                    <div style={{ display: "flex" }}>
                      <canvas
                        id="canvas"
                        ref={previewCanvasRef}
                        style={{
                          borderRadius: "50%",
                          marginLeft: "214px",
                          marginTop: "15px",
                          width: "152px",
                          height: "129px",
                          // objectFit: "contain",
                        }}
                      />
                      <div
                        style={{
                          width: "100%",
                          marginTop: "148px",
                          marginLeft: "-144px",
                          fontSize: "15px",
                          color: "black",
                        }}
                      >
                        {fullName}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div
              className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12"
              style={{ justifyContent: "center" }}
            >
              <div style={{ margin: "15px" }}>
                <button className="btn btn-primary" onClick={download}>
                  Download Image
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ImageCrp;
